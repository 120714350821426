if (import.meta.env.DEV) {
  // @ts-ignore
  import('@vite/client')
}

import Swiper from 'swiper'
import { EffectFade, Navigation, Pagination } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'

import Alpine from 'alpinejs'

import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect'

Alpine.plugin(focus)
Alpine.plugin(intersect)
Alpine.store('mobileNav', {
  on: false,

  open() {
    this.on = true
    window.addEventListener('resize', () => this.hide(), { once: true })
  },

  hide() {
    this.on = false
  },

  toggle() {
    if (this.on) this.hide()
    else this.open()
  },
} as { on: boolean; open(): void; hide(): void })

Alpine.start()

document.querySelectorAll('button[data-scroll="next-section"]').forEach((btn) => {
  btn.addEventListener('click', () => {
    btn.closest('section')?.nextElementSibling?.scrollIntoView({ behavior: 'smooth' })
  })
})
;(() => {
  document.querySelectorAll<HTMLElement>('.swiper[data-el][data-swiper-args]').forEach((swiperEl) => {
    let args: SwiperOptions = {}

    try {
      const attrs = swiperEl.dataset.swiperArgs

      if (attrs) args = JSON.parse(attrs)
    } catch (e) {
      console.log(e)
    }

    const root = swiperEl.closest<HTMLElement>('[x-data]')

    if (root) {
      const countEl = root.querySelector<HTMLElement>('.swiper-pagination-count')

      if (countEl) {
        args = {
          ...args,
          on: {
            paginationUpdate(swiper) {
              countEl.textContent = `${swiper.realIndex + 1}/${swiper.pagination.bullets.length}`

              const parent = countEl.parentElement

              if (parent) {
                parent.dataset.visible = `${swiper.pagination.bullets.length >= 2}`
              }
            },
          },
        }
      }

      args = {
        navigation: {
          nextEl: root.querySelector<HTMLElement>('[data-navigation="next"]'),
          prevEl: root.querySelector<HTMLElement>('[data-navigation="prev"]'),
        },
        pagination: {
          el: root.querySelector<HTMLElement>('.swiper-pagination'),
          clickable: true,
        },
        scrollbar: {
          el: root.querySelector<HTMLElement>('.swiper-scrollbar'),
          enabled: true,
          hide: false,
        },
        ...args,
      }
    }

    new Swiper(swiperEl, {
      modules: [Navigation, Pagination, EffectFade],
      ...args,
    })
  })
})()
